<template>
  <div>
    <div class="locale">
      <h2>个人日志列表</h2>
        <ol>
            <li><router-link to="/">后台</router-link></li>
            <li><a href="javascript:;">系统</a></li>
            <li><a href="javascript:;">操作日志</a></li>
            <li>个人的</li>
        </ol>
    </div>

    <div class="filter">
      <a-form-model layout="inline" @submit="filting" @submit.native.prevent>
        <a-form-model-item>
          <a-input v-model="filter._word" placeholder="可输入关键字" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button html-type="submit">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="box">
<!--      <div class="box-head">-->
<!--        <h3>日志列表</h3>-->
<!--      </div>-->
      <div class="box-body">
          <a-empty v-if="items.length == 0" />
          <table class="list" v-if="items.length > 0">
          <tr>
            <th style="width: 120px;">#</th>
            <th>操作内容</th>
            <th>IP地址/令牌</th>
            <th style="width: 160px;">时间</th>
            <th style="width: 80px;">操作</th>
          </tr>
          <tr v-for="item in items">
            <td>{{item.id}}</td>
            <!-- <td>
              <div>{{item.staff ? item.staff.name : item.staff_id}}</div>
            </td> -->
            <td>{{item.content}}</td>
            <td>
              <div>{{item.ip}}</div>
              <small>{{item.token}}</small>
            </td>
            <td>
              <div>{{item.created.format('yyyy-MM-dd hh:mm:ss')}}</div>
              <!--              <div>{{item.created.format('hh:mm:ss')}}</div>-->
            </td>
            <td>
                <a-button-group size="small">
                    <a-tooltip placement="top" title="详情">
                        <router-link class="ant-btn" :to="'/sys/oplog/detail/'+item.id"><a-icon type="profile" /></router-link>
                    </a-tooltip>
                </a-button-group>



<!--              <a-dropdown>-->
<!--                <a-button shape="circle" icon="down" />-->
<!--                <a-menu slot="overlay">-->
<!--                  <a-menu-item key="1"> <a-icon type="user" />1st menu item </a-menu-item>-->
<!--                  <a-menu-item key="2"> <a-icon type="user" />2nd menu item </a-menu-item>-->
<!--                  <a-menu-item key="3"> <a-icon type="user" />3rd item </a-menu-item>-->
<!--                </a-menu>-->
<!--              </a-dropdown>-->

<!--              <router-link :to="'/oplog/'+item.id"><a-icon type="profile" /></router-link>-->

            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="pager">
      <a-pagination @change="paging" :page-size="paged.size" :default-current="paged.page" :total="paged.count" show-quick-jumper/>
    </div>

  </div>
</template>


<script>
  export default {
    name: 'AppDevice',
    data() {
      return {
        first: true,
        loading: false,
        option: {
          sizes: []
        },
        filter: {
        },
        paged: {
          offset: 0, limit: 10, count: 0, size: 10, page: 0
        },
        items: [],
        last: 1,

        batched: false,
      }
    },
      mounted() {
      this.request();
    },

    methods: {
      stateText(s) {
        this.option.states.forEach((item)=>{
          if (item.value == s) {
            s = item.label;
          }
        })
        return s;
      },



      request(page) {
        let filter = {};
        for (let k in this.filter) {
          let o = this.filter[k];
          if (o && o['value'] !== undefined) {
            filter[k] = o.value;
          } else {
            filter[k] = o;
          }
        }

        filter._size = this.paged.size || 10;
        filter._index = page || 1;

        this.$get('/sys/oplog/personal', filter).then(res => {
          let ajax = res.data;
          if (ajax.data) {
            this.$util.imageField(ajax.data.items);
            this.$util.timeFields(ajax.data.items);
            if (this.first) {
              for (let k in ajax.data.option) {
                this.option[k] = ajax.data.option[k];
              }
            }
            this.items = ajax.data.items;
            this.paged = ajax.data.paged;
          }

          this.first = false;
          this.last = page;
        })
      },
      paging(page) {
        this.request(page);
      },
      filting() {
        this.request(1);
      },


      filterStaff(query) {
        if (!query) {
          this.option.staffs = [];
          return
        }
        this.loading = true;
        this.$get('/staff', {_word: query}).then(res => {
          this.loading = false;
          let ajax = res.data;
          if (ajax.code == 0) {
            if (ajax.data.items.length) {
              this.option.staffs = ajax.data.items.map((item) => {
                return {label: item.name, value: item.id};
              })
            }
          }
        }).catch(res => {
          this.loading = false;
        });
      },



      batch() {
        this.items.forEach((item)=>{
          item.batched = !this.batched;
        });
      },
      view(id) {
        this.$router.push({name: 'SysOplogView', params: { id: id }});
      }
    }
  }
</script>

<style scoped lang="less">
</style>